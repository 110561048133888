@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins" !important;
}
.custom-container {
  @apply max-w-[90%] xl:max-w-[80%] 2xl:max-w-[70%] 3xl:max-w-[60%] mx-auto;
}

.btn-hover-effect {
  transition: 0.4s ease;
}
.btn-hover-effect:hover {
  box-shadow: 6px 6px 0px #52b1b4;
}

.category-wrapper {
  white-space: nowrap;
  overflow-x: scroll;
  @apply max-w-[100%] xl:max-w-[80%] 2xl:max-w-[70%] 3xl:max-w-[60%] mx-auto;
}
.category-wrapper::-webkit-scrollbar {
  opacity: 0;
}

* {
  box-sizing: border-box;
}

.form-group {
  @apply mb-3;
}
.form-group label {
  @apply text-black mb-2 block;
}
.form-group input,
.form-group select,
.form-group textarea {
  @apply px-3 py-2 md:py-2.5 md:px-4 border border-black placeholder:text-[#4B4B4B] block w-full focus:border-green-600 focus:ring-green-600 disabled:bg-gray-200;
}
.form-group input.error,
.form-group select.error,
.form-group textarea.error {
  @apply px-3 py-2 md:py-2.5 md:px-4 border border-black placeholder:text-[#4B4B4B] block w-full border-2 border-red-600 focus:border-red-600 focus:ring-red-600 disabled:bg-gray-200;
}

.status-label {
  @apply px-2 py-1 rounded-sm md:rounded-[0px] md:px-3 md:py-2;
}
.address-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 10px;
}
.address-card {
  @apply h-full md:h-[380px];
}

.all-article-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @apply gap-5 md:gap-10;
}

.nav-items li a {
  @apply text-[14px];
}
.product-card .product-img {
  transition: 0.4s ease;
  cursor: pointer;
}
.product-card:hover .product-img {
  transform: scale(1.04);
}
.backdrop-age-verification,
.popup-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 998;
}
.backdrop-age-verification.hide,
.popup-backdrop.hide {
  animation: fadeGone 0.4s ease forwards;
}
.backdrop-age-verification.show,
.popup-backdrop.show {
  animation: fadeShow 0.4s ease forwards;
}

.toast-age-verification,
.popup-notif {
  position: fixed;
  left: 50%;
  @apply w-full max-w-[90%] h-auto md:w-[650px] md:h-[350px] bg-white;
  opacity: 1;
  z-index: 999;
  animation: fadeShow2 1s ease forwards;
}

.popup-notif {
  @apply w-full max-w-[90%] md:max-w-[45%] md:h-auto;
}

.toast-age-verification.show,
.popup-notif.show {
  animation: fadeShow2 1s ease forwards;
}
.toast-age-verification.hide,
.popup-notif.hide {
  animation: fadeGone2 1s ease forwards;
}
@keyframes fadeShow2 {
  0% {
    top: 0%;
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}
@keyframes fadeGone2 {
  0% {
    top: 50%;
    opacity: 1;
  }

  100% {
    top: 0%;
    opacity: 0;
  }
}
@keyframes fadeGone {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h5 {
  @apply text-[18px] md:text-[20px] lg:text-[22px];
  @apply my-[10px] md:my-[20px] !text-left !font-semibold;
}
.post-content ul {
  @apply text-left;
  list-style: disc !important;
  padding-left: 20px;
}
.post-content p {
  margin-bottom: 10px;
}
.faq-content ul {
  list-style: disc !important;
  padding-left: 20px;
}
.faq-content a {
  @apply text-green-600 underline;
}
.note-html ul {
  list-style-type: disc;
  padding-left: 20px;
}

:root {
  --swiper-theme-color: black !important;
}
pre {
  font-family: "Poppins", sans-serif !important;
}

.blink-animation {
  animation: blink 0.5s ease infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.withdraw-table th,
.withdraw-table td {
  @apply border border-gray-500 px-3 py-2;
}
